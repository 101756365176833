import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import PatekCategoryBtns from '../../components/preowned/PatekCategoryBtns'

// markup
const PatekAnnualCalendar = () => {
  const data = useStaticQuery(
    graphql`
      query queryPatekAnnualCalendar {
        products: allStrapiProduct(
          filter: { brand: { eq: "Patek Philippe" }, model: { regex: "/Annual/" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Patek Philippe Annual Calendar Watches for Sale'}
      canonical={'/fine-watches/patek-philippe/annual-calendar/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Patek Philippe Annual Calendar Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/patek-philippe/filter/model/annual-calendar,wempe-annual-calendar/">
              <StaticImage
                src="../../images/preowned/preowned-patek-philippe-annual-calendar-banner.png"
                alt="Pre-Owned Certified Used Patek Philippe Watches Header"
                aria-label="Used Patek Philippe Image Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">
              FIND PRE-OWNED, USED, VINTAGE, ANTIQUE, HEIRLOOM, ESTATE PATEK PHILIPPE ANNUAL
              CALENDAR WATCHES AT GRAY AND SONS
            </h1>
            <h2>
              BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED PATEK PHILIPPE ANNUAL CALENDAR WATCHES
              WITH GRAY AND SONS JEWELERS
            </h2>
            <p>
              Gray and Sons is the #1 real watch and jewelry store located in Surfside, Miami,
              Florida. Across from Bal Harbour Shops. Gray and Sons is known for our real watch and
              jewelry experts on-site, open six days a week, and that we're real, not a virtual
              store. Our customers say we are the #1 Miami used watch buyer and seller proudly
              assisting for the past 42 years. We're sellers and buyers of pre-owned, used and new
              Patek Philippe Annual Calender watches in Miami, Miami Beach, Coral Gables, Coconut
              Grove, South Beach, Bal Harbour, Surfside, Sunny Isles , Aventura, Fort Lauderdale and
              many more areas. Check out our{' '}
              <a href="https://www.youtube.com/watch?v=fjGevLO4lMU&t=6s">
                Patek Philippe Annual Calender videos on youtube{' '}
              </a>
              .
              <br />
              <br />
              Are you looking to sell or buy a Patek Philippe Annual Calendar? Top rated buyers like
              &ensp;
              <a href="/">www.grayandsons.com</a> and{' '}
              <a href="https://sellusyourjewelry.com/">sellusyourjewelry.com</a> will be your number
              one option. Gray and Sons is the best place to go for buying used Patek Philippe
              Annual Calendar watches, selling Patek Philippe Annual Calendar watches, and repairing
              Patek Philippe Annual Calendar watches. Gray and Sons watch repair shop will replace
              your Patek Philippe battery. All of our watches have been recently serviced, freshly
              lubricated, timing adjusted and detailed and ready for you to take them home. Reach
              out to us with any questions about buying and selling Patek Philippe watches.
              <br />
              <br />
              In the early-1990s, Philippe Stern, the then-president of Patek Philippe, asked his
              engineers to develop a calendar mechanism that would elegantly bridge the gap between
              a simple calendar and the ultra-complicated perpetual calendar. The result was the
              Patek Philippe Annual Calendar watch. An annual calendar automatically distinguishes
              between 30- and 31-day months and only needs to be corrected on March 1st.The first
              Ref. 5035 Patek Annual Calendar debuted at Baselworld in 1996. This novel Patek
              Philippe complication was met with such admiration that it was voted Watch of the
              Year. The Patek Philippe Annual Calendar — or quantiemè annuel in French — has evolved
              to become a high horology bestseller.
              <br />
              <br />
              Buying Pre-Owned Patek Philippe Annual Calendar Watches Over twenty Patek Annual
              Calendar models have been released since its introduction in 1996—some models even
              have the addition of other complications such as a chronograph or minute
              repeaters.Pre-owned Patek Philippe Annual Calendar watches are popular in the
              secondary market thanks to their classic looks and impressive mechanical movements.
              Like many Patek references, secondhand Patek Philippe Annual Calendar timepieces are
              collectible, desired by seasoned collectors and fine watch enthusiasts. We offer
              certified authentic and certfied pre-owned watches.
              <br />
              <br />
              Popular Used Patek Philippe Annual Calendar References Reference 5035, Reference
              5036/1, Reference 5056, Reference 5135, Reference 4936, Reference 5146, Reference
              5250, Reference 4937, Reference 5147, Reference 5350, Reference 5396, Reference 5205,
              and Reference 4947.While some of these references are no longer made by the brand,
              Gray &amp; Sons is proud to offer a wide selection of pre-owned Patek Philippe Annual
              Calendar watches, including discontinued models. When you’re buying secondhand Patek
              Annual Calendar watches, take note of the letters in the reference numbers as they
              denote the material of the watch. G = White Gold, J = Yellow Gold, R = Rose Gold, P =
              Platinum, A = Stainless Steel.
              <br />
              <br />
              Servicing Patek Philippe Annual Calendar Watches Since Patek timepieces run on
              intricate and complicated movements, only highly skilled watchmakers should be trusted
              to open them up. The first-rate service facility at Gray &amp; Sons is home to a team
              of experienced watchmakers, expertly trained to repair or service pre-owned Patek
              Annual Calendar watches. Would you like to
              <a href="/chat-with-rich"> CHAT NOW </a>
              with Viktoria or Rich? Vika and Rich are both real specialists ready to make a deal
              now.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/patek-philippe/filter/model/annual-calendar,wempe-annual-calendar/">
                <button>SHOP PATEK PHILIPPE ANNUAL CALENDAR WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK PATEK PHILIPPE WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <PatekCategoryBtns />

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/drvX30qLcgg'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default PatekAnnualCalendar
